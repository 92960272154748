import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import { createAnchor } from 'components/Navigation';
import Media from 'components/Media';
import ScrollProvider from 'components/ScrollProvider';
import { NavigationProvider } from 'components/Navigation';

import StickyNavigation from 'components/StickyNavigation';
import AppPageIntro from 'components/AppPageIntro';
import AuthoringToolNarrative from 'components/AuthoringToolNarrative';
import AuthoringToolStatic from 'components/AuthoringToolStatic';
import PilotPlan from 'components/PilotPlan';
import CardGrid from 'components/CardGrid';
import SiteFooter from 'components/SiteFooter';
import CenteredText from 'components/CenteredText';

import './industries.scss';

class RootIndex extends React.Component {
    render() {
        const { site } = get(this, 'props.pageContext');
        const {
            title,
            heading,
            introText,
            introCallToAction,
            introBackground,
            introVideo,
            featureTextHeader,
            featureTextBody,
            featureTextCta,
            narrative,
            narrativeImage,
            pilotPlan,
            cardGrid
        } = get(this, 'props.data.industries');

        return (
            <ScrollProvider>
                <NavigationProvider>
                    <div>
                      <Helmet>
                        <title>{title || site.title || 'Mira'}</title>
                      </Helmet>


                        <StickyNavigation
                            sticky={true}
                            pages={site.navigationItems}
                            sections={[]}
                            loginLink={site.loginLink}
                            callToAction={site.callToAction}
                        />

                        <AppPageIntro
                            heading={heading}
                            text={introText}
                            call={introCallToAction}
                            background={get(
                                introBackground,
                                'file.url',
                            )}
                            useSvgBg={false}
                            modal={introVideo}
                        />
                      <CenteredText heading={featureTextHeader} intro={featureTextBody} call={featureTextCta}/>
                        <CardGrid {...cardGrid} />
                        <PilotPlan {...pilotPlan} />
                        <SiteFooter
                            links={site.footerNavigationItems}
                            social={site}
                            copyright={site.copyright}
                        />
                    </div>
                </NavigationProvider>
            </ScrollProvider>
        );
    }
}

export default RootIndex;

export const pageQuery = graphql`
    query IndustriesPageQuery($id: String!) {
        site: allContentfulWebsite {
            edges {
                node {
                    id
                    title
                    navigationItems {
                        label
                        href
                    }
                    callToAction {
                        label
                        href
                    }
                    loginLink {
                        label
                        href
                    }
                    footerNavigationItems {
                        label
                        href
                    }
                    facebook
                    instagram
                    twitter
                    linkedin
                    copyright
                }
            }
        }
        industries: contentfulIndustriesPage(contentful_id: { eq: $id }) {
            id: contentful_id
            title
            heading
            introText
            introCallToAction
            introBackground {
                file {
                    url
                }
            }
            introVideo
            featureTextHeader
            featureTextBody
            featureTextCta
            cardGrid {
                name
                header
                cards {
                    image {
                        file {
                            url
                        }
                    }
                    name
                    header
                    mdText { mdText }
                    cta
                    cardLink
                }
            }
            pilotPlan {
                heading
                text
                call: callToAction {
                    label
                    href
                }
            }
        }
    }
`;
