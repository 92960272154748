import React, { useState, useEffect } from 'react';
import get from 'lodash/get';

import './CardGrid.scss';

import ReactMarkdown from 'react-markdown'

export const CardGrid = ({ header, cards }) => {
    // const rows = new Array(Math.ceil(cards.length / 3)).fill().map(_ => cards.splice(0, 3));
    return ( 
      <React.Fragment>
      <section className="card-grid-section">
          {(header !== '_none_') && <h1 className="card-grid-header">{header}</h1>}
          {cards.reduce((resultArray, item, index) => { const chunkIndex = Math.floor(index/3); if(!resultArray[chunkIndex]) { resultArray[chunkIndex] = [] };resultArray[chunkIndex].push(item);return resultArray;},[]).map((r, i)=>(            
            <div className="row" key={`row${i}`}>
                {r.map(({ header, mdText, cta, cardLink, image }, index) => (
                <div key={`${index}-${i}`} className="card">
                    <img src={get(image, 'file.url')} />
                    <div className="card-info">
                        <h3>{header}</h3>
                        <ReactMarkdown>{mdText.mdText}</ReactMarkdown>
                        {cta && (
                            <a className="cta" target={cardLink && "_blank"} href={cardLink}>
                                {cta}
                            </a>
                        )}
                    </div>
                </div>
            ))}
            </div>
          ))}

      </section>
      </React.Fragment>
  );
};

export default CardGrid;
